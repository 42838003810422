import { api } from "../../services/api";
import { IUser } from "./types";

export function setUserLocalStorage(user: IUser | null) {
    localStorage.setItem("g", JSON.stringify(user));
}

export function getUserLocalStorage() {
    const json = localStorage.getItem("g");

    if (!json) {
        return null;
    }

    const user = JSON.parse(json);

    return user;
}

export async function LoginRequest(email: string, password: string) {
    try {
        const request = await api.post("/login", { email, password });

        return request;
    } catch (error) {
        console.log(error);
        return;
    }
}
