import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
`;

export const FileData = styled.div`
    margin-left: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const Input = styled.input`
    display: none;
`;

export const Label = styled.label`
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid transparent;
    border-radius: 5px;

    svg {
        width: 60px;
        height: 60px;
    }
    &:hover {
        cursor: pointer;
        border: 1px solid #489b6c;
    }
`;

export const Disabled = styled.div`
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid transparent;
    border-radius: 5px;
    opacity: 0.6;

    svg {
        width: 60px;
        height: 60px;
    }
`;
