import React from "react";
import * as S from "./styles";

const Map = () => {
    return (
        <S.Map>
            <S.Frame
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3563.342240577558!2d-49.177579175234264!3d-26.733452632721804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dee5113a59c167%3A0x27dc5f8b1d7233ee!2sGoede%20Assessoria%20Cont%C3%A1bil!5e0!3m2!1spt-BR!2sbr!4v1631795406154!5m2!1spt-BR!2sbr"
                loading="lazy"
                title="iframeMap"
            />
        </S.Map>
    );
};

export default Map;
