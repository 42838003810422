import React from "react";
import { Background } from "./styles";
import { Params } from "./types";

const BackgroundImage = ({ width, height, imagePath, children }: Params) => {
    return (
        <Background width={width} height={height} imagePath={imagePath}>
            {children}
        </Background>
    );
};

export default BackgroundImage;
