import React from "react";
import * as S from "./styles";
import * as T from "./types";

const ServicesAdminItems = ({ item }: T.Params) => {
    return (
        <S.Item>
            <S.Icon>
                <S.Image src={item.icon} />
            </S.Icon>
            <S.Title>{item.title}</S.Title>
            <S.Desc>{item.desc}</S.Desc>
        </S.Item>
    );
};

export default ServicesAdminItems;
