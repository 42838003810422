import React, { useEffect } from "react";

import CookieConsentComp from "../../components/PublicComponents/CookieConsent";
import Development from "../../components/PublicComponents/Development";
import Footer from "../../components/PublicComponents/Footer";
import Header from "../../components/PublicComponents/Header";

const News = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <CookieConsentComp />
            <Header />
            <Development />
            <Footer />
        </>
    );
};

export default News;
