import React, { useEffect } from "react";

import BackgroundImage from "../../components/PublicComponents/BackgroundImage";
import CookieConsentComp from "../../components/PublicComponents/CookieConsent";
import Footer from "../../components/PublicComponents/Footer";
import Header from "../../components/PublicComponents/Header";
import ImageFade from "../../components/PublicComponents/ImageFade";
import ResumeForm from "../../components/PublicComponents/ResumeForm";
import VacancyList from "../../components/PublicComponents/VacancyList";

const Work = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <CookieConsentComp />
            <Header />
            <BackgroundImage width="100%" height="800px" imagePath="./images/principal.jpg">
                <ImageFade color="#505050" level={40}>
                    <ResumeForm />
                </ImageFade>
            </BackgroundImage>
            <Footer />
        </>
    );
};

export default Work;
