import styled from "styled-components";

export const Container = styled.section`
    width: 100%;
    height: 500px;
    margin-top: 100px;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ContainerDiv = styled.div`
    width: 1000px;
    height: 100%;
    display: flex;
    align-items: center;
`;

export const DataDiv = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-start;

    @media (max-width: 650px) {
        display: none;
    }
`;

export const Data = styled.div`
    width: 900px;
    height: 100%;
    padding: 20px;

    a {
        margin: 20px 0;
        padding: 10px 20px;
        border-radius: 5px;
        border: 1px solid #489b6c;
        background-color: transparent;
        text-decoration: none;
        transition: all ease-in-out 0.1s;

        &:hover {
            cursor: pointer;
            background-color: #489b6c;
            color: #fff;
        }
    }
`;

export const DataDivHidden = styled.div`
    width: 100%;
    height: 100%;
    padding: 20px;

    a {
        margin: 20px 0;
        padding: 10px 20px;
        border-radius: 5px;
        border: 1px solid #489b6c;
        background-color: transparent;
        text-decoration: none;
        transition: all ease-in-out 0.1s;

        &:hover {
            cursor: pointer;
            background-color: #489b6c;
            color: #fff;
        }
    }
    @media (min-width: 650px) {
        display: none;
    }
`;

export const Title = styled.h2`
    margin: 20px 0;
    font-size: 26px;
    text-align: center;
`;

export const Text = styled.p`
    margin: 10px 0 80px 0;
`;
