import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/authContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import GlobalStyles from "./GlobalStyles";

import About from "./pages/about";
import Contact from "./pages/contact";
import Home from "./pages/home";
import Login from "./pages/login";
import LGPD from "./pages/lgpd";
import LGPDFormPage from "./pages/lgpdForm";
import News from "./pages/news";
import Terms from "./pages/terms";
import Work from "./pages/workWithUs";

const App = () => {
    return (
        <AuthProvider>
            <BrowserRouter>
                <ToastContainer
                    position="top-center"
                    draggable={false}
                    autoClose={2500}
                    hideProgressBar={true}
                    pauseOnHover={false}
                    theme="dark"
                />
                <GlobalStyles />
                <Routes>
                    {/* Public routes */}
                    <Route path="/" element={<Home />} />
                    <Route path="/blog" element={<News />} />
                    <Route path="/contato" element={<Contact />} />
                    <Route path="/lgpd" element={<LGPD />} />
                    <Route path="/lgpd-formulario" element={<LGPDFormPage />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/privacidade" element={<Terms />} />
                    <Route path="/sobre" element={<About />} />
                    <Route path="/trabalhe-conosco" element={<Work />} />
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    );
};

export default App;
