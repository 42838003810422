import React from "react";
import { Link } from "react-router-dom";
import * as S from "./styles";

const LGPDSection = () => {
    return (
        <S.Container>
            <S.ContainerData>
                <S.Title>LGPD</S.Title>
                Está em vigor a Lei Geral de Proteção de Dados Pessoais – LGPD (Lei nº 13.709/2018), que tem como
                principal objetivo garantir a transparência e regulamentação no tratamento de dados pessoais. A Goede
                Assessoria Contábil Ltda está consciente das mudanças que essa nova lei estabelece.
                <br />
                <br />
                Diante disso, estamos providenciando toda a adequação à LGPD, seguindo as devidas premissas para atingir
                o compliance com as melhores práticas do mercado. Nosso objetivo primordial é ter a transparência de que
                os dados serão tratados com respeito e em cumprimento às normas legais.
                <br />
                <br />
                Para atingirmos essa finalidade, a Goede Assessoria Contábil Ltda investiu na contratação de assessoria
                especializada e está revendo seus procedimentos e sistemas, visando assegurar o cumprimento da lei,
                sempre zelando e respeitando os nossos usuários, clientes, colaboradores, fornecedores e demais
                interessados. É um novo passo que estamos dando a caminho da evolução e transformação digital.
                <br />
                <br />
                Para garantir a segurança e integridade dos dados, investimos em tecnologias e políticas internas para
                assegurar a segurança das informações, sendo elas: Antivírus, Firewall, Proxy, VPN, Controle de acessos,
                Antispan, entre outros.
                <S.LinksDiv>
                    <Link to="/lgpd-formulario">Formulário do Títular</Link>
                    <Link to="/privacidade">Aviso de Privacidade</Link>
                </S.LinksDiv>
            </S.ContainerData>
        </S.Container>
    );
};

export default LGPDSection;
