import React from "react";
import * as S from "./styles";

import ReportProblemIcon from '@mui/icons-material/ReportProblem';

const Development = () => {
    return (
        <S.Container>
            <ReportProblemIcon />
            EM DESENVOLVIMENTO
        </S.Container>
    )
}

export default Development