import styled from "styled-components";

export const Map = styled.section`
    width: 100%;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #464646;
`;

export const Frame = styled.iframe`
    width: 1000px;
    height: 500px;

    @media (max-width: 1050px) {
        width: 100%;
        margin: 10px;
    }
    @media (max-width: 500px) {
        height: 65%;
    }
`;
