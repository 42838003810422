import React from "react";
import { Params } from "./types";
import * as S from "./styles";

import ImageGradient from "../ImageGradientTop";

const ServiceAreaItems = ({ item }: Params) => {
    return (
        <S.Item>
            <S.Image imagePath={item.img}>
                <ImageGradient color="#464646" />
            </S.Image>
            <S.Title>{item.title}</S.Title>
            <S.Desc>{item.desc}</S.Desc>
        </S.Item>
    )
};

export default ServiceAreaItems;
