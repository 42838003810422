import React from "react";
import { Fade } from "./styles";
import { Params } from "./types";

const ImageFade = ({ color, level, children }: Params) => {
    return (
        <Fade color={color} level={level}>
            {children}
        </Fade>
    );
};

export default ImageFade;
