import styled from "styled-components";

export const Container = styled.section`
    width: 100%;
    height: 100%;
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #505050;
`;

export const ContainerArea = styled.div`
    max-width: 1000px;
    padding: 30px;
`;

export const Title = styled.h1`
    margin: 20px 0 80px 0;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
`;

export const InputDiv = styled.form`
    margin: 40px 0;
`;

export const Input = styled.input`
    width: 15px;
    height: 15px;
    margin-right: 10px;
`;

export const Label = styled.label``;

export const Link = styled.a`
    margin: 20px 0;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #489b6c;
    background-color: transparent;
    text-decoration: none;
    transition: all ease-in-out 0.1s;

    &:hover {
        cursor: pointer;
        background-color: #489b6c;
        color: #fff;
    }
`;

export const LinkDisabled = styled.a`
    margin: 20px 0;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #489b6c;
    background-color: transparent;
    text-decoration: none;
    transition: all ease-in-out 0.1s;
    opacity: 0.6;
`;
