import { createContext, useEffect, useState } from "react";
import { IAuthProvider, IContext, IUser } from "./types";
import { getUserLocalStorage, LoginRequest, setUserLocalStorage } from "./util";

export const AuthContext = createContext<IContext>({} as IContext);

export const AuthProvider = ({ children }: IAuthProvider) => {
    const [user, setUser] = useState<IUser | null>();

    useEffect(() => {
        const user = getUserLocalStorage();

        if (user) {
            setUser(user);
        }
    }, []);

    async function authenticate(email: string, password: string) {
        const response = await LoginRequest(email, password);

        if (!response?.data.token) {
            setUser(null);
            setUserLocalStorage(null);
            return response;
        }

        const payload = {
            token: response?.data.token,
            email,
            name: response?.data.name,
            office: response?.data.office,
            modules: response?.data.modules,
        };

        setUser(payload);
        setUserLocalStorage(payload);
        return response;
    }

    function logout() {
        setUser(null);
        setUserLocalStorage(null);
    }

    return <AuthContext.Provider value={{ ...user, authenticate, logout }}>{children}</AuthContext.Provider>;
};
