import styled from "styled-components";
import { Params } from "./types";

export const Gradient = styled.div<Params>`
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, ${(props) => props.color} 10%, transparent 90%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;
