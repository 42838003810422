import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import ReCAPTCHA from "react-google-recaptcha";
import { api } from "../../../services/api";
import { recaptchaKey } from "../../../config/recaptcha";
import { IResume } from "./types";
import * as S from "./styles";

import InputFile from "../../CommonComponents/InputFile";

const ResumeForm = () => {
    const { register, handleSubmit, reset } = useForm<IResume>();
    const [file, setFile] = useState<File | null>(null);
    const [acceptSend, setAcceptSend] = useState(false);
    const [recaptchaValid, setRecaptchaValid] = useState(false);

    const handleRecaptchaChange = () => {
        setRecaptchaValid(!recaptchaValid);
    };

    const handleFileChange = (fileData: File | null) => {
        if (fileData) {
            if (fileData.type !== "application/pdf") {
                toast.error("Somente arquivo no formato PDF");
                return;
            }

            // Size limit of 5MB - 5242880 Bytes
            if (fileData.size > 5242880) {
                toast.error("Arquivo muito grande, selecione um arquivo de no máximo 5MB");
                return;
            }

            setFile(fileData);
        }
    };

    const onSubmit = handleSubmit(async (data) => {
        const formData = new FormData();

        if (!file) {
            toast.error("Anexe o currículo para enviar");
            return;
        }

        if (!recaptchaValid) {
            toast.error("Faça a verificação Recaptcha");
            return;
        }

        if (data.intendedArea === "Selecione o departamento...") {
            toast.error("Selecione o departamento");
            return;
        }

        formData.append("name", data.name);
        formData.append("email", data.email);
        formData.append("phone", data.phone.replace(/[^0-9]/g, ""));
        formData.append("intendedArea", data.intendedArea);
        formData.append("file", file);

        toast.info("Enviando...");

        await api
            .post("/resume", formData, { headers: { "Content-Type": "multipart/form-data;", Accept: "*/*" } })
            .then((res) => {
                toast.success(res.data);
                reset({ name: "", email: "", phone: "", intendedArea: "" });
                setFile(null);
            })
            .catch((err) => {
                console.log(err);
                toast.error(err.response.data);
            });
    });

    return (
        <S.Form onSubmit={onSubmit}>
            <S.Title>TRABALHE CONOSCO</S.Title>
            <S.Input type="text" placeholder="Nome" maxLength={50} {...register("name", { required: true })} />
            <S.Input type="email" placeholder="E-mail" maxLength={50} {...register("email", { required: true })} />
            <InputMask
                mask="(99) 99999-9999"
                type="text"
                placeholder="Celular"
                {...register("phone", { required: true })}
            />
            <S.Select {...register("intendedArea", { required: true })}>
                <option>Selecione o departamento...</option>
                <option value="Departamento Administrativo">Departamento Administrativo</option>
                <option value="Departamento Contábil">Departamento Contábil</option>
                <option value="Departamento Financeiro">Departamento Financeiro</option>
                <option value="Departamento Fiscal">Departamento Fiscal</option>
                <option value="Departamento Pessoal">Departamento Pessoal</option>
                <option value="Serviços Gerais">Serviços Gerais</option>
                <option value="Tecnologia da Informação">Tecnologia da Informação</option>
            </S.Select>
            <S.InputDiv>
                <S.Input
                    type="checkbox"
                    id="checkData"
                    checked={acceptSend}
                    onChange={() => setAcceptSend(!acceptSend)}
                />
                <S.Label htmlFor="checkData">
                    Aceito e dou o consentimento para o tratamento de meus dados pessoais com a finalidade de
                    preenchimento da vaga de trabalho disponível com o envio de currículo em anexo.
                </S.Label>
            </S.InputDiv>
            <S.InputDiv>
                <InputFile
                    text="Importar Arquivo"
                    accept="application/pdf"
                    fileName={file?.name}
                    fileSize={file?.size}
                    disabled={acceptSend}
                    onChange={(e) => handleFileChange(e.target.files[0])}
                />
            </S.InputDiv>
            <ReCAPTCHA sitekey={recaptchaKey} theme="dark" onChange={handleRecaptchaChange} />
            {!acceptSend ? <S.ButtonDisabled>Enviar</S.ButtonDisabled> : <S.Button type="submit">Enviar</S.Button>}
        </S.Form>
    );
};

export default ResumeForm;
