import styled from "styled-components";

export const Container = styled.section`
    width: 100%;
    margin: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ContainerData = styled.div`
    max-width: 1000px;
    padding: 0 30px;
`;

export const LinksDiv = styled.div`
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    a {
        margin: 20px 0;
        padding: 10px 20px;
        border-radius: 5px;
        border: 1px solid #489b6c;
        background-color: transparent;
        text-decoration: none;
        transition: all ease-in-out 0.1s;

        &:hover {
            cursor: pointer;
            background-color: #489b6c;
            color: #fff;
        }
    }
`;

export const Title = styled.h1`
    padding: 30px 0;
    text-align: center;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
`;
