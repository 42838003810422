import styled from "styled-components";
import { Params } from "./types";

export const Fade = styled.div<Params>`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.color}${(props) => props.level};
`;
