import React, { useEffect } from "react";

import Banner from "../../components/PublicComponents/Banner";
import Footer from "../../components/PublicComponents/Footer";
import Header from "../../components/PublicComponents/Header";
import CookieConsentComp from "../../components/PublicComponents/CookieConsent";
import LGPDBanner from "../../components/PublicComponents/LGPDBanner";
import Map from "../../components/PublicComponents/Map";
import ServiceArea from "../../components/PublicComponents/ServiceArea";
import ServicesAdminList from "../../components/PublicComponents/ServicesAdminList";

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <CookieConsentComp />
            <Header />
            <Banner />
            <ServiceArea />
            <ServicesAdminList />
            <LGPDBanner />
            <Map />
            <Footer />
        </>
    );
};

export default Home;
