const secundaryServices = [
    {
        title: "Assessoria Contábil",
        desc: "Consiste em estabelecer a melhor forma de opção tributária pela empresa, com relatórios e balancete mensais e anuais para tomada de decisões.",
        icon: "./images/assessoria.svg",
    },
    {
        title: "Planejamento Tributário",
        desc: "Nosso Planejamento Tributário consiste em analisar a carga tributária das empresas entre as formas de tributação e buscar a melhor forma legal a carga tributária das empresas.",
        icon: "./images/planejamento.svg",
    },
    {
        title: "IRPF / IRPJ",
        desc: "Com pessoal especializado elaboramos a declaração de Imposto de Renda, Pessoa Jurídica e também para a Pessoa Física com orientações sobre a legislação em vigor.",
        icon: "./images/irpf.svg",
    },
    {
        title: "Contrato de Locação",
        desc: "Auxiliamos o locador a fazer o contrato de forma adequada com cláusulas simples e claras para fácil entendimento de ambas as partes. Atendendo locações de imóveis residenciais como casa e/ou apartamento.",
        icon: "./images/contrato.svg",
    },
    {
        title: "Procurações",
        desc: "Fazemos procurações particulares para transferir poderes de representação para prática de qualquer atividade, onde o outorgante não possa estar presente fisicamente. Adequados para casos simples de representação.",
        icon: "./images/procuracao.svg",
    },
    {
        title: "MEI",
        desc: "Fazemos aberturas de MEI, emissão de guias mensais, declarações anuais de faturamento e baixa.",
        icon: "./images/mei.svg",
    },
];

export default secundaryServices;
