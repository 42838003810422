import axios from "axios";
import axiosConfig from "../config/axios";
import { getUserLocalStorage } from "../context/authContext/util";

export const api = axios.create(axiosConfig);

api.interceptors.request.use((config) => {
    const user = getUserLocalStorage();
    let token = user?.token;

    // @ts-ignore
    config.headers["Authorization"] = `Bearer ${token}`;

    return config;
});
