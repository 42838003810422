import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { Link } from "react-router-dom";

const CookieConsentComp = () => {
    const getCookie = getCookieConsentValue();

    if (getCookie) {
        return <></>;
    }

    return (
        <CookieConsent
            debug={true}
            overlay
            expires={30}
            location="bottom"
            style={{ background: "#464646" }}
            buttonText="Aceitar todos os cookies"
            buttonStyle={{ padding: "5px 20px", borderRadius: "1vh", background: "#489b6c", color: "#ffffff" }}
        >
            Usamos cookies que são necessários para operar o site e melhorar sua experiência de navegação. Para saber
            mais sobre tratamento de dados e tempo de armazenamento, acesse nosso{" "}
            <Link to="/privacidade">termo de consentimento</Link>.
        </CookieConsent>
    );
};

export default CookieConsentComp;
