import React from "react";
import { Title } from "./styles";

import BackgroundImage from "../BackgroundImage";
import ImageGradient from "../ImageGradientTop";

const Banner = () => {
    return (
        <BackgroundImage width="100%" height="700px" imagePath="./images/principal.jpg">
            <ImageGradient color="#505050">
                <Title>
                    GOEDE ASSESSORIA CONTÁBIL
                </Title>
            </ImageGradient>
        </BackgroundImage>
    );
};

export default Banner;
