import styled from "styled-components";

export const Footer = styled.footer`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #464646;
`;

export const FooterArea = styled.div`
    width: 1000px;
    margin: auto;
    padding: 50px 0;
    display: flex;

    @media (max-width: 1050px) {
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
`;

export const LogoArea = styled.div`
    width: 300px;
    height: 100%;
    margin-right: 100px;

    @media (max-width: 1050px) {
        margin-right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }
`;

export const Logo = styled.div`
    width: 100%;
    height: 60px;
    margin-bottom: 80px;
    display: flex;
    align-items: center;

    @media (max-width: 1050px) {
        justify-content: center;
    }
`;

export const Menu = styled.ul`
    width: 200px;
    height: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    list-style: none;

    @media (max-width: 1050px) {
        display: none;
    }
`;

export const MenuItem = styled.li`
    margin: 5px 0;

    a {
        text-decoration: none;
    }
`;

export const MenuTitle = styled.h4`
    margin: 30px 0;
    text-align: center;
    color: #489b6c;
`;

export const SocialArea = styled.div`
    width: 1000px;
    margin: auto;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1050px) {
        width: 100%;
        justify-content: center;
    }
`;

export const SocialMedias = styled.div`
    width: 50%;
    height: 100%;
    display: flex;

    @media (max-width: 1050px) {
        justify-content: center;
    }
`;

export const SocialIcon = styled.div`
    width: 40px;
    height: 40px;
    padding: 5px;
    margin-right: 5px;
    border-radius: 5px;
    background-color: #d9d9d9;

    &:hover {
        cursor: pointer;
        background-color: #c0c0c0;
    }
    @media (max-width: 1050px) {
        margin-right: 0;
        margin: 5px;
    }
`;

export const Link = styled.a`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 100%;
        height: 100%;
    }
`;

export const SocialAction = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    svg {
        width: 40px;
        height: 40px;
        padding: 5px;
        border-radius: 5px;
        background-color: #d9d9d9;
    }
    svg:hover {
        cursor: pointer;
        background-color: #c0c0c0;
    }
    @media (max-width: 1050px) {
        display: none;
    }
`;
