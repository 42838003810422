import styled from "styled-components";
import { Params } from "./types";

export const Background = styled.section<Params>`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    background: url(${(props) => props.imagePath}) center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
`;
