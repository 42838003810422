import styled from "styled-components";

export const Container = styled.section`
    width: 100%;
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 20px;
    font-weight: 700;
    text-align: center;

    svg {
        width: 200px;
        height: 200px;
        margin: 20px;
    }
`;
