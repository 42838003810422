import styled from "styled-components";
import { ImagePath } from "./types";

export const Item = styled.div`
    width: 460px;
    height: 400px;
    margin: 10px;
    border: 1px solid #489b6c;
    border-radius: 10px;
    background-color: #464646;
`;

export const Image = styled.div<ImagePath>`
    width: 100%;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: url(${props => props.imagePath}) center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
`;

export const Title = styled.h4`
    width: 100%;
    height: 50px;
    padding: 5px;
    top: 0;
    font-size: 20px;
    text-align: center;
`;

export const Desc = styled.p`
    width: 100%;
    height: 120px;
    padding: 5px;
`;
