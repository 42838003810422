import React, { useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import ReCAPTCHA from "react-google-recaptcha";
import { api } from "../../../services/api";
import { recaptchaKey } from "../../../config/recaptcha";
import { IContact } from "./types";
import * as S from "./styles";

const ContactForm = () => {
    const { register, handleSubmit, reset } = useForm<IContact>();
    const [acceptSend, setAcceptSend] = useState(false);
    const [recaptchaValid, setRecaptchaValid] = useState(false);

    const handleRecaptchaChange = () => {
        setRecaptchaValid(!recaptchaValid);
    };

    const onSubmit = handleSubmit(async (data) => {
        if (!recaptchaValid) {
            toast.error("Faça a verificação Recaptcha");
            return;
        }

        toast.info("Enviando...");

        await api
            .post("/contact", data)
            .then((res) => {
                toast.success(res.data);
                reset({
                    name: "",
                    email: "",
                    phone: "",
                    subject: "",
                    message: "",
                });
            })
            .catch((err) => {
                toast.error(err.response.data);
            });
    });

    return (
        <S.Form onSubmit={onSubmit}>
            <S.Title>CONTATO</S.Title>
            <S.Input type="text" placeholder="Nome" maxLength={50} {...register("name", { required: true })} />
            <S.Input type="email" placeholder="E-mail" maxLength={50} {...register("email", { required: true })} />
            <InputMask
                mask="(99) 99999-9999"
                type="text"
                placeholder="Celular"
                {...register("phone", { required: true })}
            />
            <S.Input type="text" placeholder="Assunto" maxLength={50} {...register("subject", { required: true })} />
            <S.InputArea placeholder="Mensagem" maxLength={170} {...register("message", { required: true })} />
            <S.InputCheckArea>
                <S.Input
                    id="checkData"
                    type="checkbox"
                    checked={acceptSend}
                    onChange={() => setAcceptSend(!acceptSend)}
                />
                <S.InputLabel htmlFor="checkData">
                    Aceito e dou o consentimento para o tratamento de meus dados pessoais com a finalidade de manter
                    contato com a controladora para assuntos diversos.
                </S.InputLabel>
            </S.InputCheckArea>
            <ReCAPTCHA sitekey={recaptchaKey} theme="dark" onChange={handleRecaptchaChange} />
            {!acceptSend ? <S.ButtonDisabled>Enviar</S.ButtonDisabled> : <S.Button type="submit">Enviar</S.Button>}
        </S.Form>
    );
};

export default ContactForm;
