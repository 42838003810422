import React from "react";
import * as S from "./styles";

import primaryServices from "../../../constants/primaryServices";
import ServiceAreaItems from "../ServiceAreaItems";

const ServiceArea = () => {
    return (
        <S.Services>
            <S.ServicesContainer>
                <S.Title>NOSSOS SERVIÇOS</S.Title>
            </S.ServicesContainer>
            <S.FlexList>
                {primaryServices.map((item, key) => (
                    <ServiceAreaItems key={key} item={item} />
                ))}
            </S.FlexList>
        </S.Services>
    );
};

export default ServiceArea;
