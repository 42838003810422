import React from "react";
import { Link } from "react-router-dom";
import * as S from "./styles";

import BackgroundImage from "../BackgroundImage";
import ImageGradientTop from "../ImageGradientTop";
import ImageGradientRight from "../ImageGradientRight";
import ImageGradientBottom from "../ImageGradientBottom";
import ImageGradientLeft from "../ImageGradientLeft";

const LGPDBanner = () => {
    return (
        <S.Container>
            <S.ContainerDiv>
                <BackgroundImage width="100%" height="100%" imagePath="./images/lgpd.jpg">
                    <ImageGradientTop color="#505050">
                        <ImageGradientRight color="#505050">
                            <ImageGradientBottom color="#505050">
                                <ImageGradientLeft color="#505050">
                                    <S.DataDivHidden>
                                        <S.Title>LGPD</S.Title>
                                        <S.Text>
                                            A Goede tem as mais avançados protocolos de segurança para dados tanto
                                            físicos como digitais, como controle de acesso, VPN, Firewall, controle de
                                            acesso por usuário, entre outros protocolos que protegem os dados que temos
                                            armazenados.
                                        </S.Text>
                                        <Link to="/lgpd">Saiba Mais</Link>
                                    </S.DataDivHidden>
                                </ImageGradientLeft>
                            </ImageGradientBottom>
                        </ImageGradientRight>
                    </ImageGradientTop>
                </BackgroundImage>
                <S.DataDiv>
                    <S.Data>
                        <S.Title>LGPD</S.Title>
                        <S.Text>
                            A Goede tem as mais avançados protocolos de segurança para dados tanto físicos como
                            digitais, como controle de acesso, VPN, Firewall, controle de acesso por usuário, entre
                            outros protocolos que protegem os dados que temos armazenados.
                        </S.Text>
                        <Link to="/lgpd">Saiba Mais</Link>
                    </S.Data>
                </S.DataDiv>
            </S.ContainerDiv>
        </S.Container>
    );
};

export default LGPDBanner;
