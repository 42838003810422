import styled from "styled-components";

export const Item = styled.div`
    width: 300px;
    height: 300px;
    margin: 10px;
    border: 1px solid #489b6c;
    border-radius: 10px;
    background-color: #464646;
    transform: scale(0.95);
    transition: all ease 0.2s;

    &:hover {
        transform: scale(1);
    }
`;

export const Icon = styled.div`
    width: 100%;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Title = styled.h4`
    width: 100%;
    height: 50px;
    padding: 5px;
    top: 0;
    font-size: 20px;
    text-align: center;
`;

export const Desc = styled.p`
    width: 100%;
    height: 120px;
    padding: 5px;
`;

export const Image = styled.img`
    height: 65%;
`;
