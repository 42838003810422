import styled from "styled-components";

export const Form = styled.form`
    width: 600px;
    height: 700px;
    margin-top: 60px;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    background-color: #505050;

    input[type="text"],
    input[type="email"] {
        width: 90%;
        height: 30px;
        margin: 10px;
        padding: 5px;
        border: 1px solid #489b6c;
        border-radius: 10px;
        background-color: #464646;
        outline: none;
    }
`;

export const Title = styled.h1`
    margin: 20px 0;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
`;

export const InputCheckArea = styled.div`
    width: 90%;
    margin: 10px;
    display: flex;
`;

export const Input = styled.input`
    &[type="checkbox"] {
        width: 15px;
        height: 15px;
        margin-right: 10px;

        &:hover {
            cursor: pointer;
        }
    }
`;

export const InputArea = styled.textarea`
    width: 90%;
    height: 120px;
    margin: 10px;
    padding: 5px;
    border: 1px solid #489b6c;
    border-radius: 10px;
    background-color: #464646;
    outline: none;
`;

export const InputLabel = styled.label``;

export const Button = styled.button`
    margin: 20px 0;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #489b6c;
    background-color: transparent;
    text-decoration: none;
    transition: all ease-in-out 0.1s;

    &:hover {
        cursor: pointer;
        background-color: #489b6c;
        color: #fff;
    }
`;

export const ButtonDisabled = styled.div`
    margin: 20px 0;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #489b6c;
    background-color: transparent;
    text-decoration: none;
    transition: all ease-in-out 0.1s;
    opacity: 0.6;
`