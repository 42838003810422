import styled from "styled-components";
import { MenuProps } from "./types";

export const Header = styled.header`
    width: 100%;
    height: 60px;
    padding: 0 10px;
    position: fixed;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #3c3c3c;
`;

export const Logo = styled.div`
    width: 150px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const Social = styled.div`
    width: 150px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 730px) {
        display: none;
    }
`;

export const SocialMenu = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 730px) {
        display: none;
    }
`;

export const Menu = styled.ul<MenuProps>`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;

    @media (max-width: 730px) {
        height: calc(100vh - 60px);
        width: 300px;
        display: ${(props) => (!props.menuActive ? "none" : "flex")};
        position: absolute;
        right: 0;
        top: 60px;
        flex-direction: column;
        background-color: #3c3c3c;
    }
    @media (max-width: 400px) {
        width: 100%;
    }
`;

export const MenuItem = styled.li`
    height: 100%;
    padding: 0 5px;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 3px solid transparent;
    border-top: 3px solid transparent;
    transition: all ease-in-out 0.1s;

    a {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }
    &:hover {
        border-bottom: 3px solid #428961;
    }
    @media (max-width: 730px) {
        width: 100%;

        a {
            width: 100%;
        }
        &:hover {
            border-bottom: 3px solid transparent;
        }
    }
`;

export const Link = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: all ease-in-out 0.1s;

    svg {
        width: 25px;
        height: 25px;
        margin: 5px;
    }
    &:hover {
        background-color: #489b6c;
    }
    @media (max-width: 730px) {
        &:hover {
            background-color: transparent;
        }
    }
`;

export const MenuIcon = styled.div`
    width: 50px;
    height: 40px;
    padding: 10px;
    display: none;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 10px;
    background-color: #464646;

    &:hover {
        background-color: #489b6c;
        cursor: pointer;
    }
    @media (max-width: 730px) {
        display: flex;
    }
`;

export const MenuIconDiv = styled.div`
    width: 30px;
    height: 3px;
    background-color: #f0f0f0;
`;
