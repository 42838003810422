const primaryServices = [
    {
        title: "Contábil",
        desc: "Utilizado para administrar as finanças da empresa, determinando o futuro dela, com balanços e balancetes patrimoniais, demonstração de resultados, fluxo de caixa, além de entregar declarações obrigatórias, como o SPED Contábil.",
        img: "./images/accounting.jpg",
    },
    {
        title: "Fiscal",
        desc: "Utilizada para apurar os impostos das empresas, ou seja, gerar as obrigações tributárias e fiscais e seu controle. A carga tributária em nosso país é grande e as obrigações fiscais cada vez maiores. Por isso temos ferramentas que buscam suavizar o trabalho e integração das informações.",
        img: "./images/oversee.jpg",
    },
    {
        title: "Pessoal",
        desc: "Utilizado para fazer o registro de um colaborador na empresa, com sua admissão e adequação salarial e laborativa na sua classe sindical. Desenvolvemos as folhas de pagamentos bem como todas as guias e impostos pertinentes ao seu cálculo, incluídos demonstrativos extras e acordos coletivos de trabalho. Também efetuamos os demonstrativos anuais e periódicos que são essenciais para o cumprimento da legislação trabalhista.",
        img: "./images/human.jpg",
    },
    {
        title: "Societário",
        desc: "Constituímos sua empresa perante aos diversos órgãos legais (municipais, estaduais e federais) a fim de proporcionar a melhor qualificação para o objetivo da empresa. Também oferecemos os serviços de alterações, transformações e encerramento da atividade jurídica.",
        img: "./images/corporate.jpg",
    },
]

export default primaryServices;
