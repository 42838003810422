import styled from "styled-components";

export const Privicy = styled.section`
    width: 100%;
    margin: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const PrivicyArea = styled.div`
    max-width: 1000px;
    padding: 0 30px;
`;

export const Title = styled.h1`
    padding: 30px 0;
    text-align: center;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
`;

export const Subtitle = styled.h2`
    padding: 25px 0;
    font-size: 20px;
    font-weight: 700;
`;

export const Link = styled.a``;

export const Strong = styled.strong``;

export const List = styled.ul`
    padding: 15px 25px;
`;

export const ListItem = styled.li`
    margin: 5px 0;
`;
