import React from "react";
import { Params } from "./types";
import { Container, Disabled, FileData, Input, Label } from "./styles";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const InputFile = ({ text, accept, fileName, fileSize, disabled, onChange }: Params) => {
    return (
        <>
            {disabled ? (
                <Container>
                    <Input type="file" name="file" id="file" accept={accept} onChange={onChange} />
                    <Label htmlFor="file">
                        <CloudUploadIcon />
                        {text}
                    </Label>
                    <FileData>
                        {fileName ? `Nome: ${fileName}` : null}
                        <br />
                        {fileSize ? `Tamanho: ${(fileSize / 1024 / 1024).toFixed(2)}MB` : null}
                    </FileData>
                </Container>
            ) : (
                <Container>
                    <Disabled>
                        <CloudUploadIcon />
                        {text}
                    </Disabled>
                </Container>
            )}
        </>
    );
};

export default InputFile;
