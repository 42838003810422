import React, { useEffect } from "react";

import CookieConsentComp from "../../components/PublicComponents/CookieConsent";
import Footer from "../../components/PublicComponents/Footer";
import Header from "../../components/PublicComponents/Header";
import PrivacyPolicies from "../../components/PublicComponents/PrivacyPolicies";

const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <CookieConsentComp />
            <Header />
            <PrivacyPolicies />
            <Footer />
        </>
    );
};

export default Terms;
