import React, { useState } from "react";
import * as S from "./styles";

const LGPDForm = () => {
    const [check01, setCheck01] = useState(false);

    return (
        <S.Container>
            <S.ContainerArea>
                <S.Title>SOLICITAÇÃO DE ACESSO PELO TITULAR DE DADOS</S.Title>
                Você tem o direito de solicitar dados pessoais que possamos manter sobre você. Isso é conhecido como
                Solicitação de Acesso pelo Titular de Dados (“DSAR”). Se você deseja fazer um DSAR, preencha o
                formulário abaixo e encaminhe a nós por e-mail.
                <br />
                <br />
                Informamos que os seus dados pessoais (nome completo, e-mail e assinatura) serão coletados e tratados
                com a única finalidade de preenchimento e análise do Formulário de Pedido de Acesso de Titular que você
                (titular dos dados) irá encaminhar para a Controladora Goede Assessoria Contábil Ltda.
                <br />
                <br />
                Após o preenchimento, envie no endereço de e-mail:{" "}
                <a href="mailto:contato@goedeassessoria.com.br">contato@goedeassessoria.com.br</a>.
                <S.InputDiv>
                    <S.Input type="checkbox" checked={check01} onChange={() => setCheck01(!check01)} />
                    <S.Label>
                        Aceito e dou meu consentimento livre, expresso e claro para que meus dados pessoais sejam
                        tratados pela Controladora para análise do Formulário de Pedido de Acesso de Titular.
                    </S.Label>
                </S.InputDiv>
                {check01 ? (
                    <S.Link href="https://drive.google.com/file/d/1mcgPyw1tgyow8m0vwCUiykI_T-byjAPz/view?usp=sharing" target="_blank">
                        Baixar Formulário
                    </S.Link>
                ) : (
                    <S.LinkDisabled>Baixar Formulário</S.LinkDisabled>
                )}
            </S.ContainerArea>
        </S.Container>
    );
};

export default LGPDForm;
