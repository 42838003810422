import React from "react";
import * as S from "./styles";

const AboutBanner = () => {
    return (
        <S.Container>
            <S.ContainerData>
                <S.Title>SOBRE NÓS</S.Title>
                São mais de 35 anos prestando serviços de contabilidade e assessoria empresarial para as empresas de
                diversos ramos e portes de Pomerode e região.
                <br />
                <br />
                Buscamos sempre um relacionamento efetivo com nossos clientes, oferecendo caminhos e soluções que
                alcancem os resultados esperados.
                <br />
                <br />
                A empresa atua com mais de 25 colaboradores qualificados e treinados para atender com qualidade e
                pontualidade, permitindo que o negócio atinja máxima performance e eficiência e nossos sistemas garantem
                a confiabilidade da informação, auxiliando de forma assertiva a tomada de decisões.
                <br />
                <br />
                <strong>Missão: </strong>Buscar por meio de nossos colaboradores a melhoria continua dos serviços
                prestados, superando as expectativas de nossos clientes, com confiabilidade e qualidade.
                <br />
                <br />
                <strong>Visão: </strong>A Goede Assessoria cultua valores como ética, respeito aos clientes e
                colaboradores, busca incessantemente pelo conhecimento e pela excelência em tudo o que faz.
            </S.ContainerData>
        </S.Container>
    );
};

export default AboutBanner;
