import React, { useEffect } from "react";

import Footer from "../../components/PublicComponents/Footer";
import Header from "../../components/PublicComponents/Header";
import LGPDForm from "../../components/PublicComponents/LGPDForm";

const LGPDFormPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Header />
            <LGPDForm />
            <Footer />
        </>
    );
};

export default LGPDFormPage;
