import React from "react";
import * as S from "./styles";

const PrivacyPolicies = () => {
    return (
        <S.Privicy>
            <S.PrivicyArea>
                <S.Title>TERMO DE CONSENTIMENTO</S.Title>
                <S.Subtitle>1. Introdução</S.Subtitle>
                Nos Aviso de Privacidade tem por princípio respeitar a sua privacidade em relação a qualquer informação
                sua que possamos coletar em nosso site e outros sites que possuímos e operamos.
                <br />
                Solicitamos informações pessoais apenas quando realmente são necessárias para lhe fornecer um serviço.
                Fazemos por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que
                estamos coletando e como será usado. Apenas retemos as informações coletadas pelo tempo necessário para
                fornecer o serviço solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente
                aceitáveis para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não
                autorizados. Não compartilhamos informações de identificação pessoal publicamente ou com terceiros,
                exceto quando exigido por lei ou quando consentidos pelo usuário.
                <br />
                O nosso site pode ter links para sites externos que não são operados por nós. Esteja ciente de que não
                temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar responsabilidade por suas
                respectivas políticas de privacidade. Você é livre para recusar a nossa solicitação de informações
                pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados em caso de recusa.
                <br />O uso continuado de nosso site será considerado como aceitação de nossas práticas relativas à
                privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário
                e informações pessoais, entre em contato conosco, através do e-mail{" "}
                <S.Link href="mailto:contato@goedeassessoria.com.br">contato@goedeassessoria.com.br</S.Link>.<br />
                Nosso Aviso de Privacidade foi elaborado em observância aos preceitos legais da Lei do Marco Civil da
                Internet (Lei Federal n. 12.965 de 23 de abril de 2014), a LGPD – Lei Geral de Proteção de dados (Lei
                Federal n. 13.709, de agosto de 2018).
                <br />
                Poderemos, a qualquer momento, atualizar nosso Aviso de Privacidade, em observância às eventuais
                atualizações legislativas relativas às Leis Federais sobre a proteção de dados de usuários.
                <br />
                Ocorrendo atualizações, os usuários cadastrados serão informados das mesmas, por e-mail ou por avisos
                publicados em nosso site. É recomendável que o usuário consulte com regularidade nosso site e nosso
                Aviso de Privacidade para ter ciência de eventuais alterações.
                <br />
                <S.Subtitle>2. Direitos dos títulares dos dados</S.Subtitle>
                Em conformidade com o art. 18, da Lei Geral de Proteção de Dados (Lei 13.709/18), nosso site adota todas
                as metodologias e tecnologias necessárias a garantir ao usuário os direitos preceituados em lei. São
                direitos dos titulares de dados:
                <br />
                <S.List>
                    <S.ListItem>
                        <S.Strong>Confirmação da existência de tratamento de seus dados</S.Strong>, comunicados pelo
                        site quando e se houver tratamento dos dados informados, de que forma são tratados, por quanto
                        tempo e quais dados são tratados;
                    </S.ListItem>
                    <S.ListItem>
                        <S.Strong>Acesso aos dados</S.Strong>, disponibilizado por meio eletrônico, através de
                        requerimento do mesmo, podendo ser enviado eletronicamente ou entregue pessoalmente em até 48h;
                    </S.ListItem>
                    <S.ListItem>
                        <S.Strong>Correção de dados incompletos, inexatos ou desatualizados</S.Strong>;
                    </S.ListItem>
                    <S.ListItem>
                        <S.Strong>Anonimização, bloqueio ou eliminação de dados</S.Strong> desnecessários, excessivos ou
                        tratados em desconformidade com o disposto na lei;
                    </S.ListItem>
                    <S.ListItem>
                        <S.Strong>Portabilidade dos dados</S.Strong> a outro fornecedor de serviço ou produto, mediante
                        requisição expressa, de acordo com a regulamentação da autoridade nacional;
                    </S.ListItem>
                    <S.ListItem>
                        <S.Strong>Eliminação dos dados</S.Strong> pessoais tratados com o consentimento do titular,
                        exceto nas hipóteses previstas no art. 16 da Lei 13.709/18;
                    </S.ListItem>
                    <S.ListItem>
                        <S.Strong>Revogação do consentimento dado</S.Strong> pelo titular dos dados, nos termos do § 5º
                        do art. 8º da Lei;
                    </S.ListItem>
                    <S.ListItem>
                        <S.Strong>Direito a se opor</S.Strong> ao tratamento dos dados em caso de descumprimento ao
                        disposto na Lei;
                    </S.ListItem>
                    <S.ListItem>
                        Ter seus dados processados de forma transparente, licita, com finalidades específicas,
                        explícitas e legítimas, não podendo ser tratados de forma não compatível com as finalidades
                        explicitadas;
                    </S.ListItem>
                    <S.ListItem>
                        Ter seus dados coletados e tratados de forma mínima a atender às finalidades específicas a que
                        se destinam.
                    </S.ListItem>
                </S.List>
                <S.Subtitle>3. Política de Cookies</S.Subtitle>
                <S.Strong>3.1.</S.Strong> Como é prática comum em quase todos os sites profissionais, este site usa
                cookies, que são pequenos arquivos baixados no seu computador, para melhorar sua experiência. Esta
                página descreve quais informações eles coletam, como as usamos e por que às vezes precisamos armazenar
                esses cookies. Também compartilharemos como você pode impedir que esses cookies sejam armazenados, no
                entanto, isso pode fazer com que a funcionalidade do site seja prejudicada.
                <br />
                <S.Strong>3.2.</S.Strong> Utilizamos cookies por vários motivos, detalhados abaixo. Infelizmente, na
                maioria dos casos, não existem opções padrão do setor para desativar os cookies sem desativar
                completamente a funcionalidade e os recursos que eles adicionam a este site. Recomendamos que você deixe
                todos os cookies se não tiver certeza se precisa ou não deles, caso sejam usados para fornecer um
                serviço que você usa.
                <br />
                <S.Strong>3.3.</S.Strong> Você pode impedir a configuração de cookies ajustando as configurações do seu
                navegador (consulte a Ajuda do navegador para saber como fazer isso). Esteja ciente de que a desativação
                de cookies afetará a funcionalidade deste e de muitos outros sites que você visita.
                <br />
                <S.Strong>3.4.</S.Strong> Se acessar ou criar uma conta em nosso site, poderemos usar cookies para
                navegação e/ou gerenciamento do processo de inscrição e administração geral. Esses cookies geralmente
                serão excluídos quando você sair do sistema, porém, em alguns casos, eles poderão permanecer para
                lembrar as preferências do seu site ao sair. Utilizamos cookies quando você está logado, se for o caso
                de criação de conta, para que possamos lembrar dessa ação. Isso evita que você precise fazer login
                sempre que visitar uma nova página. Esses cookies são normalmente removidos ou limpos quando você efetua
                logout para garantir que você possa acessar apenas a recursos e áreas restritas ao efetuar login.
                <br />
                <S.Strong>3.5.</S.Strong> Poderemos oferecer pesquisas e questionários para fornecer informações
                interessantes, ferramentas úteis ou para entender nossa base de usuários com mais precisão. Essas
                pesquisas podem usar cookies para lembrar quem já participou ou para fornecer resultados precisos após a
                alteração das páginas.
                <br />
                <S.Strong>3.6.</S.Strong> Quando você envia dados por meio de um formulário como os encontrados nas
                páginas de contato ou na página trabalhe conosco, os cookies podem ser configurados para lembrar os
                detalhes do usuário, para correspondência futura.
                <br />
                <S.Strong>3.7.</S.Strong> Para proporcionar uma ótima experiência neste site, poderemos fornecer a
                funcionalidade para definir suas preferências de como esse site é executado quando você o usa. Para
                lembrar suas preferências, precisamos definir cookies para que essas informações possam ser chamadas
                sempre que você interagir com uma página.
                <br />
                <S.Strong>3.8.</S.Strong> Poderemos também, em alguns casos, usar cookies fornecidos por terceiros
                confiáveis, tais como, não se limitando a estes, Google, Google Analytics, que é uma das soluções de
                análise mais difundidas e confiáveis da Web, para nos ajudar a entender como você usa o site e como
                podemos melhorar sua experiência. Esses cookies podem rastrear itens como quanto tempo você gasta no
                site e as páginas visitadas, para que possamos oferecer maior experiência de usabilidade e como
                poderemos melhorar nossos serviços para você.
                <br />
                <S.Strong>3.9.</S.Strong> Também poderemos adicionar cookies aos navegadores padrão tais como Google
                Chrome, Internet Explorer, Microsoft Edge, Mozilla Firefox e, também, para acesso a sites e aplicativos
                das redes sociais, tais como Facebook, Instagram, Youtube e outros, a partir do seu navegador, com a
                finalidade de proporcionar melhor experiência de nosso site por parte do usuário. Cada rede social
                possui sua própria política de privacidade e de proteção de dados pessoais, sendo as pessoas físicas ou
                jurídicas que as mantêm responsáveis pelos dados coletados e pelas práticas de privacidade adotadas. O
                usuário pode pesquisar, junto às redes sociais, informações sobre como seus dados pessoais são tratadas.
                <br />
                <S.Strong>3.10.</S.Strong> Periodicamente, testamos novos recursos e fazemos alterações na maneira como
                o site se apresenta. Quando ainda estamos testando novos recursos, esses cookies podem ser usados ​​para
                garantir que você receba uma experiência consistente enquanto estiver no site, enquanto entendemos quais
                otimizações os nossos usuários mais apreciam.
                <S.Subtitle>4. Dever do usuário de não fornecer dados de terceiros</S.Subtitle>
                <S.Strong>4.1.</S.Strong> Durante a utilização do site, a fim de resguardar e de proteger os direitos de
                terceiros, o usuário do site deverá fornecer somente seus dados pessoais, e não os de terceiros.
                <S.Subtitle>5. Tipos de dados coletados</S.Subtitle>
                <S.Strong>5.1.</S.Strong> Dados de identificação do usuário para realização de cadastro e ou envio de
                currículo vitae.
                <br />
                A utilização, pelo usuário de determinadas funcionalidades do site, dependerá de cadastro, sendo que,
                nestes casos, os seguintes dados do usuário serão coletados e armazenados:
                <br />
                <S.Strong>5.2.</S.Strong> Dados informados no formulário de contato:
                <br />
                Os dados eventualmente informados pelo usuário que utilizar o formulário de contato disponibilizado no
                site, incluindo o teor da mensagem enviada, serão coletados e armazenados.
                <br />
                <S.Strong>5.3.</S.Strong> Dados relacionados à execução de contratos firmados com o usuário, quando for
                o caso.
                <br />
                Para a execução de contrato de prestação de serviços eventualmente firmado entre o Controlador e o
                usuário, poderão ser coletados e armazenados outros dados relacionados ou necessários a sua execução,
                incluindo o teor de eventuais comunicações mantidas com o usuário.
                <S.Subtitle>6. Registros de acesso</S.Subtitle>
                <S.Strong>6.1.</S.Strong> Em atendimento às disposições da Lei Federal n. 12.965/2014 (Marco Civil da
                Internet) e da Lei 13.709/18 (LGPD), os registros de acesso do usuário serão coletados e armazenados
                por, pelo menos,{" "}
                <S.Strong>
                    seis meses ou por tempo maior em conformidade com as especificidades de outras leis que assim o
                    exijam
                </S.Strong>
                .<S.Subtitle>7. Dados sensíveis</S.Subtitle>
                <S.Strong>7.1.</S.Strong> Nosso site não coleta dados sensíveis do usuário, tais como raça, credo,
                saúde, orientação sexual ou convicções politicas, por não serem relevantes ao tipo de prestação de
                serviços que praticamos. Tais dados não compõem nossa base e jamais você receberá comunicação nossa
                solicitando tais dados.
                <S.Subtitle>8. Consentimento para o tratamento dos dados pessoais</S.Subtitle>
                <S.Strong>8.1.</S.Strong> Ao utilizar os serviços do site, o usuário está consentindo com o presente
                Aviso de Privacidade.
                <br />
                <S.Strong>8.2.</S.Strong> O usuário tem o direito de retirar seu consentimento a qualquer momento, não
                comprometendo a licitude do tratamento de seus dados pessoais antes da retirada. O pedido para a
                retirada do consentimento poderá ser feito pelo e-mail:
                <S.Link href="mailto:contato@goedeassessoria.com.br">contato@goedeassessoria.com.br</S.Link>.<br />
                <S.Strong>8.3.</S.Strong> O consentimento dos relativamente ou absolutamente incapazes, especialmente de
                crianças menores de 16 (dezesseis) anos, apenas poderá ser feito se devidamente assistidos ou
                representados.
                <br />
                <S.Strong>8.4.</S.Strong> Poderão ainda ser coletados dados pessoais necessários para a execução e
                cumprimento dos serviços contratados pelo usuário no site. O tratamento de dados pessoais sem o
                consentimento do usuário apenas será realizado em razão de interesse legítimo ou para as hipóteses
                previstas em lei, dentre outras, as seguintes:
                <S.List>
                    <S.ListItem>Para o cumprimento de obrigação legal ou regulatória pelo controlador;</S.ListItem>
                    <S.ListItem>
                        Para a realização de estudos por órgão de pesquisa, garantida, sempre que possível, a
                        anonimização dos dados pessoais;
                    </S.ListItem>
                    <S.ListItem>
                        Quando necessário para a execução de contrato ou de procedimentos preliminares relacionados a
                        contrato do qual seja parte o usuário, a pedido do titular dos dados;
                    </S.ListItem>
                    <S.ListItem>
                        Para o exercício regular de direitos em processo judicial, administrativo ou arbitral, esse
                        último nos termos da Lei n° 9.307, de 23 de setembro de 1996 (Lei de Arbitragem);
                    </S.ListItem>
                    <S.ListItem>
                        Para a proteção da vida ou da incolumidade física do titular dos dados ou de terceiros;
                    </S.ListItem>
                    <S.ListItem>
                        Para a tutela da saúde, em procedimento realizado por profissionais da área da saúde ou por
                        entidades sanitárias;
                    </S.ListItem>
                    <S.ListItem>
                        Quando necessário para atender aos interesses legítimos do controlador ou de terceiros, exceto
                        no caso de prevalecerem direitos e liberdades fundamentais do titular dos dados que exijam a
                        proteção dos dados pessoais;
                    </S.ListItem>
                    <S.ListItem>
                        Para a proteção do crédito, inclusive quanto ao disposto na legislação pertinente.
                    </S.ListItem>
                </S.List>
                <S.Subtitle>9. Finalidades do tratamento dos dados pessoais</S.Subtitle>
                Os dados pessoais do usuário coletados pelo site têm por finalidade facilitar, agilizar e cumprir os
                compromissos estabelecidos com o usuário e fazer cumprir as solicitações realizadas somente por meio do
                preenchimento de formulários.
                <br />
                Os dados pessoais poderão ser utilizados, também, com uma finalidade comercial, para personalizar o
                conteúdo oferecido ao usuário, bem como para dar subsídio ao site para a melhora da qualidade e
                funcionamento de seus serviços.
                <br />
                <br />
                <S.Strong>9.1.</S.Strong> Os dados de cadastro serão utilizados para permitir o acesso do usuário a
                determinados conteúdos do site, exclusivos para usuários cadastrados.
                <br />
                <S.Strong>9.2.</S.Strong> A coleta de dados relacionados ou necessários à execução de um contrato de
                prestação de serviços eventualmente firmado com o usuário, terá a finalidade de conferir às partes
                segurança jurídica, além de facilitar e viabilizar a conclusão do negócio.
                <br />
                <S.Strong>9.3.</S.Strong> O tratamento de dados pessoais para finalidades não previstas neste Aviso de
                Privacidade somente ocorrerá mediante comunicação prévia ao usuário, sendo que, em qualquer caso, os
                direitos e obrigações aqui previstos permanecerão aplicáveis.
                <S.Subtitle>10. Destinatários e transferência dos dados pessoais</S.Subtitle>
                <S.Strong>10.1.</S.Strong> Os dados pessoais do usuário fornecidos através deste site não serão
                compartilhados com terceiros e, caso haja necessidade de compartilhamento, comunicaremos ao usuário por
                e-mail ou por alteração deste Aviso de Privacidade, que também será comunicada ao usuário e submetida ao
                seu consentimento e somente para terceiros cuja segurança e proteção sejam adequados à preservação dos
                direitos do usuário.
                <br />
                <S.Strong>10.2.</S.Strong> A transferência apenas poderá ser feita para outro país caso o país ou
                território em questão ou a organização internacional assegurem um nível de proteção adequado dos dados
                do usuário. Referida transferência somente será feita com o consentimento expresso do usuário.
                <S.Subtitle>11. Do tratamento dos dados pessoais</S.Subtitle>
                <S.Strong>11.1. Do responsável pelo tratamento dos dados:</S.Strong>
                <br />O controlador, responsável pelo tratamento dos dados pessoais do usuário, é a pessoa física ou
                jurídica, a autoridade pública, a agência ou outro organismo que, individualmente ou em conjunto com
                outras, determina as finalidades e os meios de tratamento de dados pessoais. Em nosso site, o
                responsável pelo tratamento dos dados pessoais coletados é a Goede Assessoria Contábil Ltda e poderá ser
                contactado pelo e-mail:{" "}
                <S.Link href="mailto:contato@goedeassessoria.com.br">contato@goedeassessoria.com.br</S.Link>. O
                responsável pelo tratamento dos dados se encarregará diretamente do tratamento dos dados pessoais do
                usuário.
                <br />
                <S.Strong>11.2. Do Encarregado de Proteção de Dados (Data Protection Officer):</S.Strong>
                <br />O Encarregado de Proteção de Dados (Data Protection Officer) é o encarregado, pessoa física ou
                jurídica, de informar, aconselhar e controlar o responsável pelo tratamento dos dados, bem corno os
                trabalhadores que tratem os dados, a respeito das obrigações do site nos termos da Lei de Geral de
                Proteção de Dados Pessoais e de outras disposições de proteção de dados presentes na legislação nacional
                e internacional, em cooperação com a autoridade de controle competente. Neste site o Encarregado de
                Proteção de Dados (Data Protection Officer) é a própria empresa Goede Assessoria Contábil Ltda, que
                poderá ser contactado pelo e-mail:{" "}
                <S.Link href="mailto:contato@goedeassessoria.com.br">contato@goedeassessoria.com.br</S.Link>.
                <S.Subtitle>12. Segurança no tratamento dos dados pessoais do usuário</S.Subtitle>
                <S.Strong>12.1.</S.Strong> Nos comprometemos a aplicar as medidas técnicas necessárias a proteger os
                dados pessoais dos usuários contra acessos não autorizados ou de possíveis alterações, perdas ou
                destruição dos dados, bem como por vazamento dos mesmos, no entanto, o site se exime de responsabilidade
                por culpa exclusiva de terceiro, corno em caso de ataque de hackers ou crackers, ou culpa exclusiva do
                usuário, como no caso em que ele mesmo transfira seus dados a terceiros.
                <br />
                <S.Strong>12.2.</S.Strong> Nos comprometemos, ainda, a comunicar o usuário em prazo adequado, caso
                ocorra algum tipo de violação da segurança de seus dados pessoais que possa lhe causar um alto risco
                para seus direitos e liberdades pessoais.
                <br />
                <S.Strong>12.3.</S.Strong> A violação de dados pessoais é uma violação de segurança que provoque, de
                modo acidental ou ilícito, a destruição, a perda, a alteração, a divulgação ou o acesso não autorizado a
                dados pessoais transmitidos, conservados ou sujeitos a qualquer outro tipo de tratamento.
                <br />
                <S.Strong>12.4.</S.Strong> Nos comprometemos a tratar os dados pessoais do usuário com
                confidencialidade, dentro dos limites legais.
                <S.Subtitle>13. Da Política de Privacidade</S.Subtitle>
                <S.Strong>13.1.</S.Strong> A presente versão desta Política de Privacidade foi atualizada pela última
                vez em 11/09/2021.
                <br />
                <S.Strong>13.2.</S.Strong> Nos reservamos o direito de modificar, a qualquer momento, a presente
                Política de Privacidade, especialmente para adaptá-las às evoluções do site, seja pela disponibilização
                de novas funcionalidades ou pela supressão ou modificação daquelas já existentes.
                <br />
                <S.Strong>13.3.</S.Strong> O usuário será devidamente notificado em caso de alteração deste aviso, mas
                aconselhamos que o usuário visite periodicamente nosso site para se informar se houve quaisquer
                alterações no presente conteúdo. Ao utilizar o serviço após eventuais modificações, o usuário concorda
                com as novas normas. Caso discorde de alguma das modificações, deverá pedir, imediatamente, o
                cancelamento de sua conta - quando for o caso - e apresentar a sua ressalva ao serviço de atendimento,
                se assim o desejar, pelos canais de contato indicados em nosso site.
                <S.Subtitle>14. Da aplicação do direito e do foro</S.Subtitle>
                <S.Strong>14.1.</S.Strong> Para a solução das controvérsias decorrentes do presente instrumento, será
                aplicado integralmente o Direito brasileiro.
                <br />
                <S.Strong>14.2.</S.Strong> Os eventuais litígios deverão ser apresentados no foro da Comarca de
                Pomerode, SC.
            </S.PrivicyArea>
        </S.Privicy>
    );
};

export default PrivacyPolicies;
