import styled from "styled-components";

export const Services = styled.section`
    width: 100%;
`;

export const ServicesContainer = styled.div`
    max-width: 1800px;
    margin: auto;
    padding: 100px 30px;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (max-width: 850px) {
        padding: 100px 10px;
    }
`;

export const Title = styled.h2`
    margin: auto;
    font-size: 26px;
    text-align: center;
`;

export const FlexList = styled.div`
    max-width: 960px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
`;
