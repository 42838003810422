import React, { useEffect } from "react";

import Footer from "../../components/PublicComponents/Footer";
import Header from "../../components/PublicComponents/Header";
import LGPDSection from "../../components/PublicComponents/LGPDSection";

const LGPD = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Header />
            <LGPDSection />
            <Footer />
        </>
    );
};

export default LGPD;
