import React from "react";
import * as S from "./styles";

import ServicesAdminItems from "../ServicesAdminItems";
import servicesList from "../../../constants/secundaryServices";

const ServicesAdminList = () => {
    return (
        <S.Services>
            <S.ServicesContainer>
                <S.Title>
                    OUTROS SERVIÇOS
                </S.Title>
            </S.ServicesContainer>
            <S.FlexList>
                {servicesList.map((item, key) => (
                    <ServicesAdminItems key={key} item={item} />
                ))}
            </S.FlexList>
        </S.Services>
    );
};

export default ServicesAdminList;
