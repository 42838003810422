import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Logo";
import * as S from "./styles";

const Footer = () => {
    return (
        <S.Footer>
            <S.FooterArea>
                <S.LogoArea>
                    <S.Logo>
                        <Logo height="80%" />
                    </S.Logo>
                    Goede Assessoria Contábil 2022
                    <br />
                    Todos os direitos reservados
                </S.LogoArea>
                <S.Menu>
                    <S.MenuTitle>MENU</S.MenuTitle>
                    <S.MenuItem>
                        <Link to="/">Home</Link>
                    </S.MenuItem>
                    <S.MenuItem>
                        <Link to="/sobre">Sobre</Link>
                    </S.MenuItem>
                    <S.MenuItem>
                        <Link to="/contato">Contato</Link>
                    </S.MenuItem>
                    <S.MenuItem>
                        <Link to="/trabalhe-conosco">Trabalhe Conosco</Link>
                    </S.MenuItem>
                    <S.MenuItem>
                        <Link to="/lgpd">LGPD</Link>
                    </S.MenuItem>
                    <S.MenuItem>
                        <S.Link href="https://onvio.com.br/#/" target="_blank" rel="noreferrer">
                            Cliente
                        </S.Link>
                    </S.MenuItem>
                </S.Menu>
                <S.Menu>
                    <S.MenuTitle>LGPD</S.MenuTitle>
                    <S.MenuItem>
                        <Link to="/lgpd-formulario">Formulário do Títular</Link>
                    </S.MenuItem>
                    <S.MenuItem>
                        <Link to="/privacidade">Aviso de Privacidade</Link>
                    </S.MenuItem>
                </S.Menu>
                <S.Menu>
                    <S.MenuTitle>ATENDIMENTO</S.MenuTitle>
                    <S.MenuItem>
                        Horário de Atendimento:
                        <br />
                        07:45 - 12:00 e 13:15 - 17:45
                        <br />
                        Segunda a Sexta
                        <br />
                        Horário de Brasília
                    </S.MenuItem>
                    <S.MenuItem>
                        Telefone:
                        <br />
                        (47) 3387-2474
                    </S.MenuItem>
                    <S.MenuItem>
                        E-mail:
                        <br />
                        contato@goedeassessoria.com.br
                    </S.MenuItem>
                </S.Menu>
            </S.FooterArea>
        </S.Footer>
    );
};

export default Footer;
