import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as S from "./styles";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import Logo from "../Logo";

const Header = () => {
    const [menu, setMenu] = useState(false);

    return (
        <S.Header>
            <S.Logo>
                <Logo height="80%" />
            </S.Logo>
            <S.MenuIcon
                onClick={() => {
                    setMenu(!menu);
                }}
            >
                <S.MenuIconDiv />
                <S.MenuIconDiv />
                <S.MenuIconDiv />
            </S.MenuIcon>
            <S.Menu menuActive={menu}>
                <S.MenuItem>
                    <Link to="/">Home</Link>
                </S.MenuItem>
                <S.MenuItem>
                    <Link to="/sobre">Sobre</Link>
                </S.MenuItem>
                <S.MenuItem>
                    <Link to="/contato">Contato</Link>
                </S.MenuItem>
                <S.MenuItem>
                    <Link to="/trabalhe-conosco">Trabalhe Conosco</Link>
                </S.MenuItem>
                <S.MenuItem>
                    <Link to="/lgpd">LGPD</Link>
                </S.MenuItem>
                <S.MenuItem>
                    <a href="https://onvio.com.br/#/" target="_blank" rel="noreferrer">
                        Cliente
                    </a>
                </S.MenuItem>
                <S.MenuItem>
                    <S.SocialMenu>
                        <S.Link href="https://www.facebook.com/goedeassessoria/" target="_blank" rel="noreferrer">
                            <FacebookIcon />
                        </S.Link>
                        <S.Link href="https://www.instagram.com/goedeassessoriacontabil/" target="_blank" rel="noreferrer">
                            <InstagramIcon />
                        </S.Link>
                        <S.Link
                            href="https://www.linkedin.com/company/goede-assessoria-contabil"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <LinkedInIcon />
                        </S.Link>
                        <S.Link
                            href="https://api.whatsapp.com/send/?phone=554733872474&text&app_absent=0"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <WhatsAppIcon />
                        </S.Link>
                    </S.SocialMenu>
                </S.MenuItem>
            </S.Menu>
            <S.Social>
                <S.Link href="https://www.facebook.com/goedeassessoria/" target="_blank" rel="noreferrer">
                    <FacebookIcon />
                </S.Link>
                <S.Link href="https://www.instagram.com/goedeassessoriacontabil/" target="_blank" rel="noreferrer">
                    <InstagramIcon />
                </S.Link>
                <S.Link
                    href="https://www.linkedin.com/company/goede-assessoria-contabil"
                    target="_blank"
                    rel="noreferrer"
                >
                    <LinkedInIcon />
                </S.Link>
                <S.Link
                    href="https://api.whatsapp.com/send/?phone=554733872474&text&app_absent=0"
                    target="_blank"
                    rel="noreferrer"
                >
                    <WhatsAppIcon />
                </S.Link>
            </S.Social>
        </S.Header>
    );
};

export default Header;
